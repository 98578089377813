var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "page-star-ratings-demo" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "overflow-auto",
              attrs: { title: "Basic", "code-toggler": "" },
            },
            [
              _c("h6", [_vm._v("Default")]),
              _c("star-rating", { attrs: { rtl: _vm.$vs.rtl } }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Half Stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, increment: 0.5 },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Preset Stars")]),
              _c("star-rating", { attrs: { rtl: _vm.$vs.rtl, rating: 4 } }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Fluid Stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, increment: 0.01, "fixed-points": 2 },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  '\n<template>\n  <!-- DEFAULT -->\n  <h6>Default</h6>\n  <star-rating :rtl="$vs.rtl" />\n\n  <!-- HALF STAR -->\n  <h6 class="mt-5">Half Stars</h6>\n  <star-rating :rtl="$vs.rtl" :increment="0.5"></star-rating>\n\n  <!-- PRESET STAR -->\n  <h6 class="mt-5">Preset Stars</h6>\n  <star-rating :rtl="$vs.rtl" :rating="4"></star-rating>\n\n  <!-- FLUID STAR  -->\n  <h6 class="mt-5">Fluid Stars</h6>\n  <star-rating :rtl="$vs.rtl" :increment="0.01" :fixed-points="2"></star-rating>\n</template>\n\n<script>\nimport StarRating from \'vue-star-rating\'\n\nexport default {\n  components: {\n    StarRating\n  }\n}\n</script>\n            '
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "overflow-auto",
              attrs: { title: "Customizing", "code-toggler": "" },
            },
            [
              _c("h6", [_vm._v("Bordered Stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "border-width": 3 },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Style Rating")]),
              _c("star-rating", {
                attrs: {
                  rtl: _vm.$vs.rtl,
                  "text-class": "text-warning font-medium",
                },
              }),
              _c("h6", { staticClass: "mt-4" }, [_vm._v("Custom Star Shape")]),
              _c("star-rating", {
                attrs: {
                  rtl: _vm.$vs.rtl,
                  "border-width": 4,
                  "border-color": "#d8d8d8",
                  "rounded-corners": true,
                  "star-points": [
                    23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                    46, 19, 31, 17,
                  ],
                },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Glowing Stars")]),
              _c("star-rating", { attrs: { rtl: _vm.$vs.rtl, glow: 10 } }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  '\n<template>\n  <!-- BORDERED -->\n  <h6>Bordered Stars</h6>\n  <star-rating :rtl="$vs.rtl" :border-width="3"></star-rating>\n\n  <!-- STYLE RATING -->\n  <h6 class="mt-5">Style Rating</h6>\n  <star-rating :rtl="$vs.rtl" text-class="text-warning font-medium"></star-rating>\n\n  <!-- CUSTOM SHAPE -->\n  <h6 class="mt-4">Custom Star Shape</h6>\n  <star-rating :rtl="$vs.rtl" :border-width="4" border-color="#d8d8d8" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>\n\n  <h6 class="mt-5">Glowing Stars</h6>\n  <star-rating :rtl="$vs.rtl" :glow="10"></star-rating>\n</template>\n\n<script>\nimport StarRating from \'vue-star-rating\'\n\nexport default {\n  components: {\n    StarRating\n  }\n}\n</script>\n            '
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "overflow-auto",
              attrs: { title: "Sizing", "code-toggler": "" },
            },
            [
              _c("h6", [_vm._v("Small Stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "star-size": 20 },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Big Stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "star-size": 80 },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  '\n<template>\n  <!-- SMALL STARS -->\n  <h6>Small Stars</h6>\n  <star-rating :rtl="$vs.rtl" :star-size="20"></star-rating>\n\n  <!-- LARGE STARS -->\n  <h6 class="mt-5">Big Stars</h6>\n  <star-rating :rtl="$vs.rtl" :star-size="80"></star-rating>\n</template>\n\n<script>\nimport StarRating from \'vue-star-rating\'\nexport default {\n  components: {\n    StarRating\n  }\n}\n</script>\n            '
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "overflow-auto",
              attrs: { title: "Changing color", "code-toggler": "" },
            },
            [
              _c("h6", [_vm._v("Red Stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "active-color": "#EA5455" },
              }),
              _c("h6", { staticClass: "mt-4" }, [_vm._v("Vibrant Stars")]),
              _c("star-rating", {
                attrs: {
                  rtl: _vm.$vs.rtl,
                  "inactive-color": "#b9b4f9",
                  "active-color": "#7367f0",
                },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  '\n<template>\n  <!-- RED STAR -->\n  <h6>Red Stars</h6>\n  <star-rating :rtl="$vs.rtl" active-color="#EA5455"></star-rating>\n\n  <!-- VIBRANT STAR -->\n  <h6 class="mt-4">Vibrant Stars</h6>\n  <star-rating :rtl="$vs.rtl" inactive-color="#b9b4f9" active-color="#7367f0"></star-rating>\n</template>\n\n<script>\nimport StarRating from \'vue-star-rating\'\n\nexport default {\n  components: {\n    StarRating\n  }\n}\n</script>\n            '
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "overflow-auto",
              attrs: {
                title: "Capture, Reset or Hide Rating",
                "code-toggler": "",
              },
            },
            [
              _c("h6", [_vm._v("Capture Rating")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "show-rating": false },
                on: { "rating-selected": _vm.setRating },
              }),
              _c("div", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.rating)),
              ]),
              _c("h6", { staticClass: "mt-5" }, [
                _vm._v("Capture Mouse Over Rating"),
              ]),
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block" },
                  on: {
                    mouseleave: function ($event) {
                      return _vm.showCurrentRating(0)
                    },
                  },
                },
                [
                  _c("star-rating", {
                    attrs: {
                      rtl: _vm.$vs.rtl,
                      "show-rating": false,
                      increment: 0.5,
                    },
                    on: {
                      "current-rating": _vm.showCurrentRating,
                      "rating-selected": _vm.setCurrentSelectedRating,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.currentRating)),
              ]),
              _c("h6", { staticClass: "mt-5" }, [
                _vm._v("Resetable stars with v-model (Vue 2.2+)"),
              ]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl },
                model: {
                  value: _vm.boundRating,
                  callback: function ($$v) {
                    _vm.boundRating = $$v
                  },
                  expression: "boundRating",
                },
              }),
              _c("div", { staticClass: "font-semibold" }, [
                _c(
                  "a",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.boundRating = 0
                      },
                    },
                  },
                  [_vm._v("Reset Rating")]
                ),
              ]),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Hide Rating")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "show-rating": false },
              }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  '\n<template>\n  <!-- CAPTURE RATING -->\n  <h6>Capture Rating</h6>\n  <star-rating :rtl="$vs.rtl" :show-rating="false" @rating-selected="setRating"></star-rating>\n  <div class="font-semibold">' +
                    _vm._s(_vm.rating) +
                    '</div>\n\n  <!-- CAPTURE ON HOVER -->\n  <h6 class="mt-5">Capture Mouse Over Rating</h6>\n  <div @mouseleave="showCurrentRating(0)" style="display:inline-block;">\n    <star-rating :rtl="$vs.rtl" :show-rating="false" @current-rating="showCurrentRating" @rating-selected="setCurrentSelectedRating" :increment="0.5"></star-rating>\n  </div>\n  <div class="font-semibold">' +
                    _vm._s(_vm.currentRating) +
                    '</div>\n\n  <!-- RESET WTIH V-MODEL -->\n  <h6 class="mt-5">Resetable stars with v-model (Vue 2.2+)</h6>\n  <star-rating :rtl="$vs.rtl" v-model="boundRating"></star-rating>\n  <div class="font-semibold"><a @click="boundRating = 0;" class="cursor-pointer">Reset Rating</a></div>\n\n  <!-- HIDE RATIG -->\n  <h6 class="mt-5">Hide Rating</h6>\n  <star-rating :rtl="$vs.rtl" :show-rating="false"></star-rating>\n</template>\n\n<script>\nimport StarRating from \'vue-star-rating\'\n\nexport default {\n  methods: {\n    setRating: function(rating) {\n      this.rating = "You have Selected: " + rating + " stars";\n    },\n    showCurrentRating: function(rating) {\n      this.currentRating = (rating === 0) ? this.currentSelectedRating : "Click to select " + rating + " stars"\n    },\n    setCurrentSelectedRating: function(rating) {\n      this.currentSelectedRating = "You have Selected: " + rating + " stars";\n    }\n  },\n  data(){\n    return {\n      rating: "No Rating Selected",\n      currentRating: "No Rating",\n      currentSelectedRating: "No Current Rating",\n      boundRating: 3,\n    }\n  },\n  components: {\n    StarRating\n  }\n}\n</script>\n            '
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "overflow-auto",
              attrs: { title: "Other useful options", "code-toggler": "" },
            },
            [
              _c("h6", [_vm._v("Non rounded start rating")]),
              _c("star-rating", {
                attrs: {
                  rtl: _vm.$vs.rtl,
                  rating: 4.67,
                  "round-start-rating": false,
                },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Read Only Stars")]),
              _c("star-rating", {
                attrs: {
                  rtl: _vm.$vs.rtl,
                  rating: 3.8,
                  "read-only": true,
                  increment: 0.01,
                },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Lots of stars")]),
              _c("star-rating", {
                attrs: { rtl: _vm.$vs.rtl, "max-rating": 10, "star-size": 20 },
              }),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("Inline Stars")]),
              _vm._v(" Rated\n            "),
              _c("star-rating", {
                attrs: {
                  rtl: _vm.$vs.rtl,
                  inline: true,
                  "star-size": 20,
                  "read-only": true,
                  "show-rating": false,
                  rating: 5,
                },
              }),
              _vm._v(" by our customers.\n\n            "),
              _c("h6", { staticClass: "mt-5" }, [_vm._v("RTL Stars")]),
              _c("star-rating", { attrs: { rtl: true, increment: 0.5 } }),
              _c("template", { slot: "codeContainer" }, [
                _vm._v(
                  '\n<template>\n  <!-- NON ROUNDED -->\n  <h6>Non rounded start rating</h6>\n  <star-rating :rtl="$vs.rtl" :rating="4.67" :round-start-rating="false"></star-rating>\n\n  <!-- READ ONLY -->\n  <h6 class="mt-5">Read Only Stars</h6>\n  <star-rating :rtl="$vs.rtl" :rating="3.8" :read-only="true" :increment="0.01"></star-rating>\n\n  <!-- LOTS OF STARS -->\n  <h6 class="mt-5">Lots of stars</h6>\n  <star-rating :rtl="$vs.rtl" :max-rating="10" :star-size="20"></star-rating>\n\n  <!-- INLINE -->\n  <h6 class="mt-5">Inline Stars</h6> Rated\n  <star-rating :rtl="$vs.rtl" :inline="true" :star-size="20" :read-only="true" :show-rating="false" :rating="5"></star-rating> by our customers.\n\n  <!-- RTL -->\n  <h6 class="mt-5">RTL Stars</h6>\n  <star-rating :rtl="true" :increment="0.5"></star-rating>\n</template>\n\n<script>\nimport StarRating from \'vue-star-rating\'\n\nexport default {\n  components: {\n    StarRating\n  }\n}\n</script>\n            '
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }